@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
  #MainImg {
    position: relative;
    #MainImgInner {
      padding: 0;
      border-top-width: 0;
    }
    .video {
      position: relative;
      width: 100%;
      &_item {
        width: 100%;
      }
      &_btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 78px;
        height: 48px;
        background: url(../img/contents/icon_02.png) no-repeat top left;
        background-size: 78px auto;
        cursor: pointer;
        &.playing { display: none; }
      }
    }
  }

  #Main {
    .contBox {
      padding: 47px 0 0;
      text-align: center;
      .boxTitle {
        font-size: 24px;
        color: #78590c;
        letter-spacing: .3em;
      }
    }
    .contSubBox {
      margin-bottom: 45px;
      .subBoxTitle {
        font-size: 18px;
        color: #78590c;
        letter-spacing: .2em;
        padding-bottom: 25px;
        border-bottom: 1px solid #d8d8d8;
      }
    }
    #ContBox01 {
      .contSubBox {
        margin-bottom: 20px;
        .subBoxTitle {
          padding-top: 25px;
        }
        .subBoxCont {
          padding: 0;
        }
      }
      .contSubBox01 {
        p {
          text-align: center;
          margin-top: 9px;
          margin-bottom: 36px;
        }
      }
      .contSubBox02 {
        .area {
          padding-top: 25px;
          overflow: auto;
          &_text {
            text-align: center;
            margin-bottom: 16px;
            clear: both;
          }
          &_col {
            width: 50%;
            float: left;
            border-bottom: 1px solid #d8d8d8;
            position: relative;
            .area_list {
              .list_item {
                &_cont {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
                span {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 73px;
                  font-size: 11px;
                  line-height: 16px;
                }
              }
            }
            &_left:after {
              position: absolute;
              content: '';
              width: 1px;
              height: 100%;
              background-color: #d8d8d8;
              top: 0;
              right: 0;
              transform: translateX(0.5px);
              z-index: -1;
            }
          }
        }
        .westArea {
          padding-top: 40px;
        }
      }
      .contSubBox03 {
        border-bottom: 1px solid #d8d8d8;
        .subBoxCont {
          padding: 29px 15px;
        }
        .company_table {
          width: 100%;
          table-layout: fixed;
          th {
            font-size: 12px;
            font-weight: 500;
            color: #888;
          }
          td {
            width: 72.8%;
            line-height: 2;
            padding: 7px 0 8px;
            text-align: justify;
            a {
              display: inline-block;
              padding-right: 18px;
              background: url(../img/contents/icon_01.png) no-repeat right center;
              background-size: 11px auto;
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      .contSubBox04 {
        padding-top: 10px;
        margin-bottom: 0;
        .subBoxTitle {
          border-bottom-width: 0;
        }
        .map {
          margin-bottom: 35px;
          iframe {
            width: 100%;
            height: 232px;
          }
          p {
            position: relative;
            margin: 19px 15px 0;
            padding-left: 20px;
            &:before {
              position: absolute;
              content: '';
              width: 13px;
              height: 1px;
              background-color: #c9a43b;
              top: 10px;
              left: 0;
            }
            span {
              display: block;
              color: #888;
              line-height: 1.7;
              margin-top: 2px;
            }
          }
        }
        .map02 {
          margin-bottom: 0;
        }
      }
    }
    #ContBox02 {
      .boxTitle {
        padding-bottom: 23px;
        border-bottom: 1px solid #d8d8d8;
      }
      .contSubBox {
        padding-top: 40px;
        padding-bottom: 41px;
        margin-bottom: 0;
      }
    }
    #ContBox03 {
      background-color: #f7f3e7;
      padding-top: 54px;
      .boxTitle {
        margin-bottom: 23px;
      }
      .contSubBox {
        border-top: 1px solid #e3d9ba;
        padding-top: 45px;
        padding-bottom: 50px;
        margin-bottom: 0;
        .subBoxTitle {
          border-bottom-width: 0;
        }
        .subBoxCont {
          .number {
            position: absolute;
            width: 35px;
            height: 35px;
            background-color: #c9a43b;
            font-size: 21px;
            color: #fff;
            line-height: 35px;
            padding-left: .05em;
          }
        }
      }
      .contSubBox01 {
        border-top: 1px solid #e3d9ba;
        padding-top: 31px;
        padding-bottom: 33px;
        p {
          line-height: 2;
          letter-spacing: .1em;
        }
      }
      .contSubBox02 {
        .subBoxTitle {
          letter-spacing: .15em;
          margin-bottom: 20px;
        }
        .featureBox {
          position: relative;
          padding-left: 10px;
          margin-bottom: 40px;
          &:last-of-type {
            margin-bottom: 0;
          }
          &_number {
            top: -10px;
            left: 0;
          }
          &_img {
            img {
              width: 100%;
              height: auto;
            }
          }
          &_text {
            background-color: #fff;
            padding: 14px 15px;
            p {
              line-height: 1.7;
              letter-spacing: .1em;
            }
          }
        }
      }
      .contSubBox03 {
        padding-bottom: 65px;
        .subBoxTitle {
          margin-bottom: 27px;
        }
        .stepBox {
          position: relative;
          background-color: #fff;
          padding: 0 15px;
          margin-bottom: 49px;
          &_number {
            top: -18px;
            left: 0;
          }
          &_text {
            position: relative;
            padding-top: 27px;
            padding-bottom: 30px;
            p {
              color: #888;
              line-height: 1.7;
              letter-spacing: .1em;
              span {
                display: block;
                position: relative;
                color: #333;
                padding-left: 18px;
                &:before {
                  position: absolute;
                  content: '';
                  width: 13px;
                  height: 1px;
                  background-color: #c9a43b;
                  top: 11px;
                  left: 0;
                }
              }
            }
          }
          &_left {
            margin-left: 10px;
            .stepBox {
              &_img {
                width: 130px;
                height: 130px;
                position: absolute;
                top: -18px;
                left: -10px;
              }
              &_text {
                margin-left: 125px;
              }
            }
          }
          &_right {
            margin-right: 10px;
            .stepBox {
              &_img {
                width: 130px;
                height: 130px;
                position: absolute;
                top: -18px;
                right: -10px;
              }
              &_text {
                margin-right: 125px;
              }
            }
          }
          &:last-of-type {
            margin-bottom: 0;
            .stepBox{
              &_img {
                position: relative;
                top: 15px;
              }
              &_text {
                padding-bottom: 0;
                padding-left: 10px;
              }
              &_number {
                left: 10px;
              }
            }
          }
        }
      }
      .contSubBox04 {
        .subBoxCont {
          .list {
            text-align: left;
            &_item {
              position: relative;
              padding-left: 20px;
              color: #888;
              line-height: 2;
              text-align: justify;
              margin-bottom: 15px;
              &:before {
                position: absolute;
                top: 1em;
                left: 0;
                content: '';
                width: 13px;
                height: 1px;
                background-color: #c9a43b;
              }
              &:last-of-type {
                margin-bottom: 0;
              }
            }
            &_subject {
              display: block;
              color: #333;
            }
          }
        }
      }
      .contSubBox05 {
        .subBoxTitle {
          margin-bottom: 9px;
        }
        .form_table {
          th {
            span {
              font-size: 12px;
            }
          }
          td {
            input, textarea {
              width: 100%;
              background-color: #fff;
              letter-spacing: .1em;
              border: 1px solid #e3d9ba;
              padding: 11px 15px;
            }
            textarea {
              height: 155px;
              resize: vertical;
            }
            input::placeholder {
              color: #bdbdbd;
            }
            textarea::placeholder {
              color: #bdbdbd;
            }
            .option {
              display: inline-block;
              width: 50%;
              float: left;
              & > div {
                span {
                  width: 30px;
                  height: 30px;
                  background: url(../img/contents/checkBox.jpg) no-repeat top left;
                  background-size: 30px auto;
                  &.checked {
                    background-image: url(../img/contents/checkBox_check.jpg);
                  }
                }
                input {
                  width: 100%;
                  height: 100%;
                  border-width: 0;
                }
              }
              &_text {
                display: inline-block;
                margin-left: 9px;
              }
              &:nth-of-type(3) {
                display: block;
                width: 100%;
                margin-top: 15px;
              }
            }
            &.input_old {
              input {
                width: calc( 100% - 24px );
              }
              .input_text {
                display: inline-block;
                margin-left: 9px;
              }
            }
            &:after {
              display: block;
              content: '';
              clear: both;
            }
          }
        }
      }
      .contSubBox06 {
        .scrollBox {
          width: 100%;
          height: 440px;
          margin-bottom: 30px;
          position: relative;
          &:before {
            position: absolute;
            width: calc( 100% - 7px);
            height: 28px;
            content: '';
            background-color: #fff;
            left: 1px;
            top: 1px;
          }
          &:after {
            position: absolute;
            width: calc( 100% - 7px );
            height: 30px;
            content: '';
            background-color: #fff;
            left: 1px;
            bottom: 1px;
          }
          &_cont {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            background-color: #fff;
            padding: 22px 30px;
            border: 1px solid #e3d9ba;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #ece3c8;
              height: 119px;
            }
          }
          &_item {
            text-align: justify;
            letter-spacing: .1em;
            line-height: 2;
            margin-bottom: 15px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .checkBox {
          display: inline-block;
          margin-bottom: 40px;
          .option {
            display: block;
            &_text {
              display: inline-block;
              margin-left: 10px;
            }
          }
          .checker {
            span {
              width: 30px;
              height: 30px;
              background: url(../img/contents/checkBox.jpg) no-repeat top left;
              background-size: 30px auto;
              transform: translateY(-1px);
              &.checked {
                background-image: url(../img/contents/checkBox_check.jpg);
              }
            }
            input {
              width: 100%;
              height: 100%;
              border-width: 0;
            }
          }
        }
      }
      .contSubBox07 {
        text-align: left;
        strong {
          display: block;
          line-height: 1.6;
          letter-spacing: .1em;
          margin-bottom: 3px;
        }
        p {
          line-height: 1.6;
          letter-spacing: .1em;
          color: #888;
          a {
            color: #888;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    #ContBox04 {
		padding-top: 0;
		padding-bottom: 50px;
		a{
			display: block;
			border: 1px solid #e3d9ba;
			padding: 24px 5px 25px;
			span{
				display: block;
				font-size: 18px;
				color: #78590c;
				&::after{
					content: "";
					position: relative;
					display: inline-block;
					margin-left: 13px;
					width: 11px;
					height: 11px;
					background-repeat: no-repeat;
					background-position: center center;
					background-image: url("../img/contents/icon_01.png");
					background-size: 11px auto;
					letter-spacing: .1em;
				}
			}
			b{
				font-size: 11px;
				color: #000;
			}
		}
	}
  }
}

/*
確認画面
-------------------------------------*/
#PageContact.pageConfirm {
  background-color: #f7f3e7;
  #Main {
    #ContBox01 {
      .contSubBox {
        padding-bottom: 56px;
        .text {
          margin-bottom: 32px;
          p {
            text-align: center;
            line-height: 2;
          }
          &.error {
            p:first-of-type {
              margin-bottom: 14px;
            }
          }
        }
        .form_table {
          border-collapse: separate;
          background-color: #fff;
          padding: 27px 20px 23px;
          margin-bottom: 40px;
          tr:nth-of-type(9) {
            display: none;
          }
          td {
            line-height: 2;
            letter-spacing: .1em;
            text-align: justify;
            padding: 8px 0 20px;
          }
        }
        .sendBtn {
          margin-bottom: 27px;
        }
        .back {
          display: inline-block;
          color: #888;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

/*
送信完了画面
-------------------------------------*/
#PageContact.pageThanks {
  background-color: #f7f3e7;
  #Main {
    #ContBox01 {
      .contSubBox {
        .text {
          margin-bottom: 33px;
          p {
            line-height: 2;
            letter-spacing: .1em;
            text-align: center;
          }
        }
      }
    }
  }
}

/*
ブログ
-------------------------------------*/
#PageTopics.pageIndex {
  #Main {
    #ContBox01 {
      .contSubBox {
        padding-bottom: 60px;
        .btn {
          background-image: url(../img/contents/arrow_down.png);
          background-size: 13px auto;
        }
      }
    }
  }
}

/*
記事詳細ページ
-------------------------------------*/
#PageTopics.pageEntry {
  #Main {
    #ContBox01 {
      .contSubBox {
        padding-top: 4px;
        padding-bottom: 60px;
        .postHead {
          margin-bottom: 15px;
          .postTime {
            display: block;
            font-size: 10px;
            color: #888;
            letter-spacing: .2em;
            margin-bottom: 2px;
          }
          .postTitle {
            font-size: 16px;
            letter-spacing: .1em;
          }
        }
        .postCont {
          margin-bottom: 45px;
          p {
            line-height: 24px;
          }
          img {
            margin: 0 0 30px !important;
          }
          * + img {
            margin-top: 30px !important;
          }
        }
        .postNav {
          // text-align: center;
          &_cont {
            width: 100%;
            height: 46px;
            position: relative;
            margin-bottom: 20px;
          }
          &_item {
            height: 46px;
            position: absolute;
            top: 0;
            a {
              display: block;
              width: 100%;
              height: 100%;
              line-height: 46px;
              background-color: #c99f3b;
              background-repeat: no-repeat;
              background-size: 5px auto;
              font-size: 14px;
              color: #fff;
            }
            &.prev {
              width: calc( calc( 58.8% - 20px ) / 2 );
              left: 0;
              a {
                text-align: right;
                padding-right: 9px;
                background-image: url(../img/contents/arrow_prev.png);
                background-position: left 10px center;
              }
            }
            &.close {
              width: 41.2%;
              left: 50%;
              transform: translateX(-50%);
              a {
                text-align: center;
              }
            }
            &.next {
              width: calc( calc( 58.8% - 20px ) / 2 );
              right: 0;
              a {
                padding-left: 9px;
                background-image: url(../img/contents/arrow_next.png);
                background-position: right 10px center;
              }
            }
          }
          .olderPost {
            display: inline-block;
            color: #888;
            text-decoration: underline;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}



@media print,
screen and (min-width: 1000px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Main {
      #ContBox01 {
        .contSubBox02 {
          .area_col {
            &_right {
              .area_list {
                .list_item {
                  span {
                    border-right: 1px solid #d8d8d8;
                  }
                }
              }
            }
            &_left {
              .area_list {
                .list_item {
                  span {
                    border-left: 1px solid #d8d8d8;
                  }
                }
              }
            }
          }
        }
        .contSubBox03 {
          .subBoxCont {
            padding: 29px 0;
          }
        }
        .contSubBox04 {
          .map p {
            margin: 19px 0 0;
          }
        }
      }
      #ContBox03 {
        .contSubBox05 {
          .form_table {
            td {
              .option {
                width: 127px;
              }
            }
          }
        }
      }
    }
  }
}


@media print,
screen and (min-width: 376px) {
  /*
  記事詳細ページ
  -------------------------------------*/
  #PageTopics.pageEntry {
    #Main {
      #ContBox01 {
        .contSubBox {
          .postNav_item {
            &.prev a,
            &.next a {
              text-align: center;
            }
          }
        }
      }
    }
  }
}


@media print,
screen and (max-width: 349px) {
  /*
  記事詳細ページ
  -------------------------------------*/
  #Page.pageIndex #Main {
    #ContBox01 {
      .contSubBox02 {
        .area_col {
          &_right {
            .area_list {
              .list_item:nth-of-type(3) span br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  #PageTopics.pageEntry #Main #ContBox01 {
    .contSubBox {
      .postNav_item {
        &.prev {
          a {
            background-image: none;
          }
        }
        &.next {
          a {
            background-image: none;
          }
        }
      }
    }
  }
}


// IE10+（Edgeも全て含む）
_:-ms-lang(x), .btn {
  line-height: 64px;
}
_:-ms-lang(x), .menu .subMenu_item .menu_item_cont::before {
  top: 46%;
}
_:-ms-lang(x), .menu .subMenuBtn {
  line-height: 49px;
}
/*
トップページ
-------------------------------------*/
_:-ms-lang(x), #Page.pageIndex #Main #ContBox03 .contSubBox06 .scrollBox:before {
  width: calc(100% - 30px);
  height: 22px;
  top: 1.5px;
  left: 2px;
}
_:-ms-lang(x), #Page.pageIndex #Main #ContBox03 .contSubBox06 .scrollBox:after {
  width: calc(100% - 30px);
  height: 22px;
  bottom: 2px;
  left: 2px;
}
_:-ms-lang(x), #Page.pageIndex #Main #ContBox03 .contSubBox06 .scrollBox_cont {
  scrollbar-base-color: transparent;
  scrollbar-face-color: #ece3c8;
  scrollbar-highlight-color: transparent;
  scrollbar-track-color: white;
  scrollbar-arrow-color: #ece3c8;
  scrollbar-shadow-color: #ece3c8;
  scrollbar-dark-shadow-color: transparent;
}

/*
記事詳細
-------------------------------------*/
_:-ms-lang(x), #PageTopics.pageEntry #Main #ContBox01 .contSubBox .postNav_item a {
  line-height: 50px;
}
_:-ms-lang(x), #PageTopics.pageEntry #Main #ContBox01 .contSubBox .postNav_item.next {
  width: 28.4%;
}
_:-ms-lang(x), #PageTopics.pageEntry #Main #ContBox01 .contSubBox .postNav_item.prev {
  width: 28.4%;
}